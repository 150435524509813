import { Modal } from "antd";
import { ModalProps } from "./modal-types";
import { useWindowResize } from "../../hooks/use-window-resize";
import { setModalWidthSize } from "./modal-utils";
import "./styles.css";

const CCModal = ({
  title,
  children,
  open,
  onClose,
  className,
  isClosable = true,
}: ModalProps) => {
  const resizeWidth = useWindowResize();

  return (
    <Modal
      className="custom-ant-modal"
      title={title}
      open={open}
      footer={null}
      onCancel={onClose}
      centered
      maskClosable={isClosable}
      width={setModalWidthSize(resizeWidth)}
    >
      <div className={className || ""}>{children}</div>
    </Modal>
  );
};

export default CCModal;
