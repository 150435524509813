// customToast.js
import { toast } from "react-toastify";
import "./toaster-style.css";

const customToast = {
  success(msg: any, options = {}) {
    return toast.success(msg, {
      className: "success-toast-style",
      autoClose: 3000,
      closeButton: false,
    });
  },
  error(msg: any, options = {}) {
    return toast.error(msg, {
      className: "error-toast-style",
      autoClose: 3000,
      closeButton: false,
    });
  },
  info(msg: string, options = {}) {
    return toast.info(msg, {
      className: "info-toast-style",
      closeButton: false,
    });
  },
};

export default customToast;
