import React from "react";
import PrimaryButtonComponent from "./PrimaryButton";
import OutlineButtonComponent from "./OutlineButton";
import { getLabel } from "../../hooks/use-labels";
import SimpleButton from "./SimpleButton";

interface ButtonProps {
  type: string;
  label?: string;
  size?: string;
  disable?: boolean;
  onClick?: () => void;
  htmlType?: "button" | "reset" | "submit" | undefined;
  className?: string;
  icon?: string;
  width?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  btnBackgroundHoverColor?: string;
  iconPrefix?: boolean;
  svgIcon?: React.ReactNode;
  iconPostfix?: boolean;
}

interface ElementProps {
  [key: string]: () => React.ReactNode;
}

const Button = ({ type, ...props }: ButtonProps): React.ReactElement => {
  const elementButton: ElementProps = {
    simple: () => <SimpleButton {...props} type={type} />,
    primary: () => <PrimaryButtonComponent {...props} type={type} />,
    outline: () => <OutlineButtonComponent {...props} type={type} />,
  };

  const buttonElement = elementButton[type];

  if (buttonElement) {
    return buttonElement() as React.ReactElement;
  } else {
    console.error(getLabel("buttonNotFound"));

    return <div>{getLabel("buttonNotFound")}</div>;
  }
};

export default Button;
