import { Fragment, useState } from "react";
import { authTokenAvailable } from "./utils/modular-function";

import AppContainer from "./bootstrap/AppContainer";
import AuthAPIs from "./bootstrap/AuthAPIs";
import Loader from "./components/shared/loader";

import "./App.css";
import "./ui-kit/typography/styles.css";
import "chart.js/auto";

const App = (): JSX.Element => {
  const authToken = authTokenAvailable();
  const [loading, setLoading] = useState(authToken);

  return (
    <Fragment>
      {<AuthAPIs setLoading={setLoading} />}
      {!loading ? <AppContainer /> : <Loader />}
    </Fragment>
  );
};

export default App;
