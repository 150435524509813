import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import RefreshAuth from "../views/RefreshAuth";
import ThemeLayout from "../components/ThemeLayout";
import Loader from "../components/shared/loader";
import IdleTimeoutComponent from "../views/IdleTimeoutComponent";

import "react-toastify/dist/ReactToastify.css";
import { prepareAppRoutes } from "./app-route-utils";

const AppContainer = () => {
  const routes = prepareAppRoutes();

  return (
    <ThemeLayout>
      <Suspense fallback={<Loader />}>
        <Routes>
          {routes}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <ToastContainer />
        <RefreshAuth />
        <IdleTimeoutComponent />
      </Suspense>
    </ThemeLayout>
  );
};

export default AppContainer;
