import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import Init from "./bootstrap/init";

import "react-phone-number-input/style.css";

import { store } from "./app-store";

const container = document.getElementById("root") as any;
const root = createRoot(container);
root.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider theme={{ hashed: false }}>
          <Init />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
