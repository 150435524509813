import _ from "lodash"
import { getStyle } from "../../hooks/use-styles"



const getStyleProp = (prop: string, key: string): object => {
    let style: StyleObject = {};
    if (getStyle(key)) {
        style[prop] = getStyle(key)
        return style
    }

    return style
}



export const getSingleMetaStyle = (prop: string, key: string): object => {
    return { style: getStyleProp(prop, key) }
}


interface StyleObject {
    [key: string]: string;
}

const getInlineStyleProp = (styles: Array<object>): StyleObject => {
    let style: StyleObject = {};

    if (!_.isEmpty(styles)) {
        styles.forEach((styleProp: any) => {
            for (const property in styleProp) {
                if (styleProp.hasOwnProperty(property)) {
                    if (styleProp[property].includes("!important")) {
                        style[property] = styleProp[property].replace("!important", "").trim() + ' !important';
                    } else {
                        style[property] = styleProp[property];
                    }
                }
            }
        });
    }

    return style;
};

export const setinLineStyleProps = (props: Array<object>): object => {
    return { style: getInlineStyleProp(props) }

}


