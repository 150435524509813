import { Button } from "antd";
import React, { useEffect } from "react";

export interface PrimaryButtonProps {
  type: string;
  label?: string;
  size?: string;
  disable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  htmlType?: "button" | "reset" | "submit" | undefined;
  className?: string;
  icon?: string;
  style?: React.CSSProperties;
  width?: string;
  loading?: boolean;
  btnBackgroundHoverColor?: string;
}

const SimpleButton = ({
  type,
  label,
  size = "lg",
  disable = false,
  onClick,
  htmlType,
  className,
  icon,
  width,
  style,
  loading,
  btnBackgroundHoverColor,
}: PrimaryButtonProps) => {
  const styles = {
    width: `${width}px`,
    ...style,
  };

  useEffect(() => {
    if (btnBackgroundHoverColor) {
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style>
              .ant-btn-default.consumer-btn:not(:disabled):hover{
              background-color:${btnBackgroundHoverColor} !important;
              border-color: ${btnBackgroundHoverColor} !important;
          }
            </style>`
      );
    }
  }, [btnBackgroundHoverColor]);

  return (
    <Button
      htmlType={htmlType}
      id={label}
      className={`${className} consumer-btn button-box-shadow button-${
        size ? size : "default"
      } ${!disable ? `${type}-enabled` : `${type}-disabled`}`}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        !disable && onClick && onClick(event);
      }}
      style={{ ...styles }}
      loading={loading}
    >
      {label && label}
      {icon && (
        <span className="button-icon-container">
          <img src={icon} alt="button-icon" />
        </span>
      )}
    </Button>
  );
};

export default SimpleButton;
