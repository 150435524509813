import React, { useEffect } from "react";
import { Button } from "antd";
import { getStyle } from "hooks/use-styles";

export interface PrimaryButtonProps {
  type: string;
  label?: string;
  size?: string;
  disable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  htmlType?: "button" | "reset" | "submit" | undefined;
  className?: string;
  icon?: string;
  style?: React.CSSProperties;
  width?: string;
  loading?: boolean;
  btnBackgroundHoverColor?: string;
  iconPrefix?: boolean;
  svgIcon?: React.ReactNode;
  iconPostfix?: boolean;
}

const PrimaryButtonComponent = ({
  type,
  label,
  size = "lg",
  disable = false,
  onClick,
  htmlType,
  className,
  icon,
  width,
  style,
  loading,
  btnBackgroundHoverColor,
  iconPrefix = false,
  svgIcon,
  iconPostfix = false,
}: PrimaryButtonProps) => {
  const styles = {
    width: `${width}px`,
    backgroundColor: getStyle("btnBgPrimaryColor"),
    borderColor: getStyle("btnBgPrimaryColor"),
    color: "#fff",
    ...style,
  };

  useEffect(() => {
    if (btnBackgroundHoverColor) {
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style>
        .ant-btn-default.consumer-btn:not(:disabled):hover{
              background-color:${btnBackgroundHoverColor} !important;
              border-color: ${btnBackgroundHoverColor} !important;
          }
        </style>`
      );
    }
  }, [btnBackgroundHoverColor]);

  return (
    <Button
      htmlType={htmlType}
      id={label}
      className={`${className} consumer-btn button-box-shadow button-${
        size ? size : "default"
      } ${!disable ? `${type}-enabled` : `${type}-disabled`}`}
      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        !disable && onClick && onClick(event);
      }}
      style={{ ...styles }}
      loading={loading}
      ghost={false}
    >
      {svgIcon && iconPrefix && (
        <span className="primary-pre-svgicon">{svgIcon}</span>
      )}
      {icon && iconPrefix && (
        <span className="primary-button-icon">
          <img src={icon} alt="primary-icon" />
        </span>
      )}

      {label && label}

      {icon && !iconPrefix && (
        <span className="button-icon-container">
          <img src={icon} alt="button-icon" />
        </span>
      )}
      {svgIcon && iconPostfix && (
        <span className="primary-post-svgicon">{svgIcon}</span>
      )}
    </Button>
  );
};

export default PrimaryButtonComponent;
