export const setModalWidthSize = (resizeWidth: number) => {

    switch (true) {
        case resizeWidth < 375:
            return "300px";
        case resizeWidth < 575:
            return "350px";
        case resizeWidth < 767:
            return "500px";
        case resizeWidth < 991:
            return "700px";
        default:
            return "700px";
    }
}