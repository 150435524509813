import { Fragment, useEffect, useState } from "react";
import { initialize } from "../apis/init_api";
import { getSecureUrl } from "../utils/modular-function";
import { getAuthTokenAPI } from "../apis/auth_api";
import { useNavigate } from "react-router-dom";
import { verifyAuthTokenRoute } from "./bootstrap-utils";
import Metadata from "./MetaData";
import { checkImageSrc, showErrorNotification } from "../utils/common-utils";
import { getLabel } from "../hooks/use-labels";
import Favicon from "react-favicon";

const Init = (): JSX.Element => {
  const [initialized, setInitialized] = useState(false);
  const surl: any = getSecureUrl();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = getLabel("brandName");
  }, []);

  useEffect(() => {
    if (surl.includes("secure-urls")) {
      const getAuthTokenObj: object = getAuthTokenAPI(surl);
      const { redirectPath } = verifyAuthTokenRoute(getAuthTokenObj);

      navigate(redirectPath);
    }
  }, [surl, navigate]);

  useEffect(() => {
    initialize()
      .then((res) => {
        setInitialized(true);
      })
      .catch((error) => {
        console.log(error);
        showErrorNotification(error);
        setInitialized(false);
      });
  }, []);

  return initialized ? (
    <Fragment>
      <Favicon url={checkImageSrc("brandFavicon")} />
      <Metadata />
    </Fragment>
  ) : (
    <Favicon url={checkImageSrc("brandFavicon")} />
  );
};

export default Init;
