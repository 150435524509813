import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BetweenAccountState {
    currencyExchangeRate: string;
    convertedAmount: number;
}

const initialState: BetweenAccountState = {
    currencyExchangeRate: "",
    convertedAmount: 0,
};

const betweenAccountSlice = createSlice({
    name: "Between Slice",
    initialState,
    reducers: {
        setCurrencyExchangeRate(state, action: PayloadAction<string>) {
            state.currencyExchangeRate = action.payload;
        },
        setConvertedAmount(state, action: PayloadAction<number>) {
            state.convertedAmount = action.payload;
        },
    },
});

export const { setCurrencyExchangeRate, setConvertedAmount } = betweenAccountSlice.actions;
export default betweenAccountSlice.reducer;