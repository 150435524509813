import { combineReducers } from "@reduxjs/toolkit";
import users from "../reducers/user/user-reducer";
import publicSlice from "../reducers/public/public-Slice";
import betweenAccountSlice from "../reducers/between-accounts/between-account-Slice";

const rootReducer = combineReducers({
    users: users,
    public: publicSlice,
    transferBetweenAccounts: betweenAccountSlice,
});

export default rootReducer;