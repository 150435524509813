import _ from "lodash";
import { getPageUrl } from "../utils/modular-function";
import { AuthTokenAPIRedirectionProps } from "./bootstrap-types";



export const verifyAuthTokenRoute = (
  authTokenObject: object
): AuthTokenAPIRedirectionProps => {
  if (!_.isEmpty(authTokenObject)) return { redirectPath: "/" };

  return { redirectPath: "/login" };
};

export const authTokenAPIredirection = (
  authTokenCallBackUrl: boolean | undefined,
  accessTokenInLogInAPI: string,
  consumerId: string
): AuthTokenAPIRedirectionProps => {
  if (authTokenCallBackUrl || accessTokenInLogInAPI) {
    const currentUrl = getPageUrl();

    if (currentUrl.includes("auth_token") || consumerId) {
      return { redirectPath: "/" };
    } else {
      return { redirectPath: `/${currentUrl}` };
    }
  } else {
    return { redirectPath: "/login" };
  }
};

export const authTokenWithLogin = (
  consumerId: string
): AuthTokenAPIRedirectionProps => {
  const currentUrl = getPageUrl();
  const isAuthToken = currentUrl.includes("auth_token");
  if (isAuthToken || consumerId) {
    return { redirectPath: "/" };
  } else {
    return { redirectPath: `/${currentUrl}` };
  }
};
