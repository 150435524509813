import axios from "axios";
import { AUTH_APP_ID, AUTH_KEY, AUTH_URL, B2C_URL } from "../credentials";
import { APIErrorResponse } from "../interfaces/api";
import { showErrorNotification } from "./common-utils";
type BaseUrlType = "AUTH_URL" | "B2C_URL" | "";
const getOptions = (providedHeaders?: object, responseType?: string) => {
  const commonHeaders = {
    "x-api-key": AUTH_KEY(),
    "x-app-id": AUTH_APP_ID(),
    // Origin: "http://localhost:4206/",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
    ...providedHeaders,
  };
  const options: any = {
    headers: commonHeaders,
  };
  if (responseType) {
    options.responseType = responseType;
  }
  return options;
};

const prepareURL = (baseUrlType: BaseUrlType | undefined, url: string) => {
  // By Default, the base URL is AUTH_URL
  switch (baseUrlType) {
    case "B2C_URL":
      const b2cURL = B2C_URL();
      const baseUrl = b2cURL.split("/graphql")[0];
      return `${baseUrl?.trim()}${url}`;
    default:
      return `${AUTH_URL()}${url}`;
  }
};

const extractAxiosError = async (errorResponse: any) => {
  const { response, request } = errorResponse;
  let errorObject = {} as APIErrorResponse

  if (request.responseType === "blob") {
    const resp = await errorResponse.response.data.text();
    const parseResponse = JSON.parse(resp).errors;
    errorObject.message = parseResponse.message;
    return errorObject;
  }
  if (response && response.data) {
    const { data } = response
    if (data.errors) {
      errorObject = data.errors;
      if (data.errors.field_error && data.errors.field_error.length > 0) {
        const field_errors = data.errors.field_error;
        for (const field_error of field_errors) {
          showErrorNotification(field_error.details)
        }
      }
    } else {
      errorObject.error_code = data.error_code
      errorObject.message = data.message
      errorObject = {
        ...errorObject,
        ...(data.additional_data && { additional_data: data.additional_data })
      }
    }
    return errorObject
  }

  return null;
};
const handleAxiosError = async (error: any) => {
  const axiosError = await extractAxiosError(error);
  if (axiosError) {
    return Promise.reject(axiosError);
  }
  return Promise.reject({
    error_code: "GENERAL",
    message: "Something went wrong!",
  });
};

const axiosAPI = {
  post: (url: string, data: object, headers?: object, baseUrl?: BaseUrlType) =>
    axios
      .post(`${prepareURL(baseUrl, url)}`, data, getOptions(headers))
      .catch(handleAxiosError),
  get: (url: string, headers?: object, baseUrl?: BaseUrlType) =>
    axios
      .get(`${prepareURL(baseUrl, url)}`, getOptions(headers))
      .catch(handleAxiosError),
  put: (url: string, data: object, headers?: object) =>
    axios
      .put(`${prepareURL("", url)}`, data, getOptions(headers))
      .catch(handleAxiosError),
  delete: (url: string) =>
    axios
      .delete(`${prepareURL("", url)}`, getOptions())
      .catch(handleAxiosError),
  blob: (url: string, headers?: object, baseUrl?: BaseUrlType) =>
    axios
      .get(`${prepareURL(baseUrl, url)}`, getOptions(headers, "blob"))
      .catch(handleAxiosError),
};

export default axiosAPI;
