import { styles } from "../utils/metadata";
import defaults from "../utils/defaults";
import { getLanguage } from "../utils/language-helper";

const useStyles = (keys: Array<string>): any => {
  const stylesData = {} as any;

  const mdStyles = styles();
  const languageCode = getLanguage();

  keys.forEach((key) => {
    let styleInfo = mdStyles.find(
      (s: any) =>
        s.config_code === key &&
        (s.language_code ? s.language_code === languageCode : true)
    );

    if (!styleInfo) {
      styleInfo = mdStyles.find((s: any) => s.config_code === key);
    }

    stylesData[key] = styleInfo ? `#${styleInfo.config_value}` : defaults[key];
  });

  return stylesData;
};

export default useStyles;

export const getStyle = (key: string) => {
  const labelData = styles();
  const languageCode = getLanguage();

  const labelInfo = labelData.find(
    (label: any) =>
      label.config_code === key &&
      (label.language_code ? label.language_code === languageCode : true)
  );

  return labelInfo
    ? labelInfo.config_value
      ? labelInfo.config_value
      : defaults[key]
    : defaults[key];
};
