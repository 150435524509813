import { getStyle } from "../../../hooks/use-styles";
import { SessionExpiryModalProps } from "../modal-types";

import Button from "../../../ui-kit/Button";

import "./styles.css";
import FlexButtons from "../../FlexButtons";
import { getLabel } from "../../../hooks/use-labels";

const SessionExpiryModal = ({
  title,
  description,
  timer,
  onConfirm,
  className,
  logOut,
}: SessionExpiryModalProps) => {
  return (
    <div
      className={`${
        className
          ? "session-expiry-modal-container " + className
          : "session-expiry-modal-container"
      }`}
    >
      <div className="session-expiry-modal-content">
        <div className="session-expiry-modal-meta">
          <div className="session-expiry-modal-title">{title}</div>
          <div className="session-expiry-modal-description">{description}</div>
          <div className="session-expiry-modal-timer">{timer}</div>
        </div>
        <div className="session-expiry-modal-btn-container">
          <FlexButtons className="session-expiry-btn-container">
            <Button
              type="outline"
              label={getLabel("logoutLabel")}
              onClick={logOut}
              htmlType="button"
              size="md"
              className="primary-outline-btn"
              btnBackgroundHoverColor={getStyle("themeSecondaryColor")}
            />
            <Button
              type="primary"
              label={getLabel("stayLabel")}
              onClick={onConfirm}
              htmlType="submit"
              size="md"
              className="primary-btn"
              btnBackgroundHoverColor={getStyle("themeSecondaryColor")}
            />
          </FlexButtons>
        </div>
      </div>
    </div>
  );
};

export default SessionExpiryModal;
