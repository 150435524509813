import { useEffect, useState } from "react";
import App from "../App";
import Loader from "../components/shared/loader";
import { setMetadata } from "../utils/metadata";
import { fetchMetaData } from "../apis/b2c_api";
import { showErrorNotification } from "../utils/common-utils";

const Metadata = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchMetaData()
      .then((metadata: any) => {
        setMetadata(metadata?.metadata ? JSON.parse(metadata.metadata) : {});
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showErrorNotification(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <Loader className="metadata-loader" />;

  return <App />;
};

export default Metadata;
