import React, { Fragment } from "react";
import { getBrandIdName } from "../../utils/common-utils";

interface Props {
  children: React.ReactNode;
}

const ThemeLayout = (props: Props) => {
  const { children } = props;
  const className = getBrandIdName();

  return (
    <Fragment>
      <main className={`main ${className}-main`}>{children}</main>
    </Fragment>
  );
};

export default ThemeLayout;
