import { Route, Navigate } from "react-router-dom";
import { NestedRouteProps, RouteProps } from "../utils/route-types";
import { appRoutes } from "../utils/routes";
import _ from "lodash";

export const prepareAppRoutes = () => {
  const routes = appRoutes.map(
    ({ key, path, component: Component, nestedRoutes }: RouteProps) => {
      const nestedRouteList =
        nestedRoutes && !_.isEmpty(nestedRoutes) ? (
          nestedRoutes.map(
            ({
              key,
              path,
              component: NestedComponent,
              redirect = false,
              to,
            }: NestedRouteProps) => {
              if (redirect) {
                return (
                  <Route
                    key={key}
                    path={path}
                    element={<Navigate to={to ? to : "/"} />}
                  />
                );
              }

              return (
                <Route key={key} path={path} element={<NestedComponent />} />
              );
            }
          )
        ) : (
          <></>
        );

      return (
        <Route key={key} path={path} element={<Component />}>
          {nestedRouteList}
        </Route>
      );
    }
  );

  return routes;
};
